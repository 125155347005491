import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./UserBlockedCountry.css";
import { translate, t } from "react-multi-lang";
import {
    fetchBlockedCountryStart,
    deleteBlockedCountryStart,
} from "../../store/actions/BlockCountryAction";
import useInfiniteScroll from "../helper/useInfiniteScroll";
import NoDataFound from "../NoDataFound/NoDataFound";
import HomeLoader from "../Loader/HomeLoader";

const UserBlockedCountryIndex = (props) => {

    useEffect(() => {
        props.dispatch(fetchBlockedCountryStart());
    }, []);
    console.log(props.blockedCountries);
    const fetchHomeData = () => {
        setTimeout(() => {
          if (props.blockedCountries.length !== 0) {
            props.dispatch(fetchBlockedCountryStart());
            setIsFetching(false);
          } else {
            setNoMoreData(true);
          }
        }, 3000);
    };
    
    const [isFetching, setIsFetching] = useInfiniteScroll(fetchHomeData);

    const [noMoreData, setNoMoreData] = useState(false);

    const handleDeleteData = (event, user_restrict_access_id) => {
        event.preventDefault();
        props.dispatch(deleteBlockedCountryStart({ user_restrict_access_id: user_restrict_access_id }));
    };

    return (
        <>
        <div className="user-blocked-country-sec">
            <Container>
            <h4 className="head-title-1">Blocked Countries</h4>
                <Row>
                    <Col sm={12} md={12}>
                        <div>                          
                          <div className="pull-right">
                            {props.blockedCountries.loading ? null : (
                              <Link
                              to={"/restrict-access"}
                              className="post-btn btn-primary"
                              >
                              {props.blockedCountries.data.countryData.length > 0 ? (
                                t("edit")
                            ) : t("add")}
                            </Link>
                            )}
                          </div>
                        </div>
                    </Col>

                    {props.blockedCountries.loading ? (
                        <HomeLoader />
                    ) : props.blockedCountries.data.countryData.length > 0 ? (
                        props.blockedCountries.data.countryData.map((countries) => (
                            
                            <Col md={12}>
                                <div className="user-blocked-country-box">
                                    <Row>
                                        <Col md={3}>
                                            <div className="country-blocked-sec">
                                                <h5>{countries.country}</h5>
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="country-btn-action-sec">
                                                <Button 
                                                    className="btn-delete-country-sec" 
                                                    onClick={(event) => handleDeleteData(event, countries.user_restrict_access_id)}
                                                >Delete</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        ))
                    ) : (
                        <NoDataFound />
                    )}
                    {noMoreData !== true ? (
                        <>{isFetching && "Fetching more list items..."}</>
                    ) : (
                        t("no_more_data")
                    )}
                </Row>
            </Container>            
        </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    blockedCountries: state.blockCountry.blockedCountries,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(UserBlockedCountryIndex));
