import React, { Component } from "react";
import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchCountrySuccess,
  fetchCountryFailure,
  saveUserRestrictAccessSuccess,
  saveUserRestrictAccessFailure,
  fetchBlockedCountrySuccess,
  fetchBlockedCountryFailure,
  deleteBlockedCountrySuccess,
  deleteBlockedCountryFailure,
  fetchBlockedCountryStart,
} from "../actions/BlockCountryAction";

import api from "../../Environment";
import {
  FETCH_COUNTRY_START,
  SAVE_USER_RESTRICT_ACCESS_START,
  FETCH_BLOCKED_COUNTRY_START,
  DELETE_BLOCKED_COUNTRY_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";

function* fetchCountryAPI() {
  try {
    const inputData = yield select(
      (state) => state.blockCountry.countryInputData.data
    );
    const response = yield api.postMethod("get_country_code", inputData);
    yield put(fetchCountrySuccess(response.data));
    if (response.data.success) {
    } else {
      yield put(fetchCountryFailure(response));
    }
  } catch (error) {
    yield put(fetchCountryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* saveUserRestrictAccessAPI() {
  try {
    const userData = yield select(
      (state) => state.blockCountry.saveRestrictAccess.inputData
    );
    const response = yield api.postMethod("user_restrict_access_save", userData);
    if (response.data.success) {
      yield put(saveUserRestrictAccessSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/user-blocked-country");
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(saveUserRestrictAccessFailure(response.data.error));
    }
  } catch (error) {
    yield put(saveUserRestrictAccessFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* fetchBlockedCountryAPI() {
  try {
    const skipCount = yield select((state) => state.blockCountry.blockedCountries.skip);
    const response = yield api.postMethod("user_restricted_access_list", { skip: skipCount });
    yield put(fetchBlockedCountrySuccess(response.data.data));
    if (response.data.success) {
      
    } else {
      yield put(fetchBlockedCountryFailure(response));
    }
  } catch (error) {
    yield put(fetchBlockedCountryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* deleteBlockedCountryAPI() {
  try {
    const inputData = yield select((state) => state.blockCountry.delblockedCountry.inputData);
    const response = yield api.postMethod("user_restricted_access_delete", inputData);
    if (response.data.success) {
      yield put(deleteBlockedCountrySuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      // yield put(fetchBlockedCountryStart());
      window.location.reload();
    } else {
      yield put(deleteBlockedCountryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deleteBlockedCountryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_COUNTRY_START, fetchCountryAPI),
    yield takeLatest(SAVE_USER_RESTRICT_ACCESS_START, saveUserRestrictAccessAPI),
    yield takeLatest(FETCH_BLOCKED_COUNTRY_START, fetchBlockedCountryAPI),
    yield takeLatest(DELETE_BLOCKED_COUNTRY_START, deleteBlockedCountryAPI),
  ]);
}
