import {
  FETCH_USER_STORIES_START,
  FETCH_USER_STORIES_SUCCESS,
  FETCH_USER_STORIES_FAILURE,
  STORY_FILE_UPLOAD_START,
  STORY_FILE_UPLOAD_SUCCESS,
  STORY_FILE_UPLOAD_FAILURE
} from './ActionConstant'

export function fetchUserStoriesStart(data) {
  return {
    type: FETCH_USER_STORIES_START,
    data,
  };
}

export function fetchuserStoriesSucess(data) {
  return {
    type: FETCH_USER_STORIES_SUCCESS,
    data,
  };
}

export function fetchUserStoriesFailure(error) {
  return {
    type: FETCH_USER_STORIES_FAILURE,
    error,
  };
}

export function storyFileUploadStart(data) {
  return {
    type: STORY_FILE_UPLOAD_START,
    data,
  };
}

export function storyFileUploadSuccess(data) {
  return {
    type: STORY_FILE_UPLOAD_SUCCESS,
    data,
  };
}

export function storyFileUploadFailure(error) {
  return {
    type: STORY_FILE_UPLOAD_FAILURE,
    error,
  };
}