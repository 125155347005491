import React, { useEffect, useState } from "react";
import HeaderIndex from "./Header/HeaderIndex";
import NewFooter from "./Footer/NewFooter";
// import SideBarIndex from "./SideBar/SideBarIndex";
import { Notify } from "react-redux-notify";
import AuthFooter from "./Footer/AuthFooter";
import LatestFooter from "./Footer/LatestFooter";
import { Helmet } from "react-helmet";

const MainLayout = (props) => {
  const [themeState, setThemeState] = useState(
    localStorage.getItem("dark_mode")
      ? localStorage.getItem("dark_mode") == "true"
        ? true
        : false
      : false
  );

  const toggleClass = () => {
    setThemeState(!themeState);
    localStorage.setItem("dark_mode", !themeState);
  };

  return (
    <div className={`${themeState ? "dark-mode" : ""}`}>
      <div className="app-admin-wrap layout-sidebar-large ">
        <Notify position="TopRight" />
        <HeaderIndex toggleTheme={toggleClass} />
        {/* <SideBarIndex /> */}

        <div className="main-content-wrap sidenav-open d-flex flex-column">
          <div className="main-wrap-sec">
            {React.cloneElement(props.children)}
          </div>
          {/* <NewFooter /> */}
          <LatestFooter />
          {/* <AuthFooter /> */}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
