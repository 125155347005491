import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import "../Accounts/Payments/AddBankIndex.css";
import { translate, t } from "react-multi-lang";
import {
    fetchCountryStart,
    saveUserRestrictAccessStart,
} from "../../store/actions/BlockCountryAction";
import Select from "react-dropdown-select";

const RestrictAccessIndex = (props) => {

    const [profileInputData, setProfileInputData] = useState({});

    useEffect(() => {
      if(props.countryCodes.loading) 
        props.dispatch(fetchCountryStart());
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(saveUserRestrictAccessStart(profileInputData));
    };

    const handleInputChange = (inputValue) => {
      let array = [];
      inputValue.map((value, i) => {     
        array.push(value.value);
      });
      setProfileInputData({ ...profileInputData, country: array });
  };

    return (
        <div className="card-list-sec">
        {props.countryCodes.loading ? (
            t("loading")
        ) : 
        <Container>
            <h4 className="head-title">{t("restrict_access")}</h4>
            <Row>
            <Col sm={12} md={12}>
                <div className="add-bank-box">
                <Form onSubmit={handleSubmit}>
                  
                    <div>
                        <Col md={6}>
                            <Form.Group controlId="formHorizontalNickname">
                                <Form.Label>{t("country")}: (*)</Form.Label>
                                <Select
                                  multi={true}
                                  name="country"
                                  values={props.countryCodes.data.data.restricted_countries}
                                  onChange={(values) => handleInputChange(values)}
                                  keepSelectedInList={true}
                                  options={props.countryCodes.data.data.all_countries}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  />

                            </Form.Group>
                        </Col>
                        
                    </div>
                       
                    <div className="edit-save">
                    <Button
                        className="save-btn"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={props.saveRestrictAccess.buttonDisable}
                    >
                        {props.saveRestrictAccess.loadingButtonContent !== null
                        ? props.saveRestrictAccess.loadingButtonContent
                        : t("submit")}
                    </Button>
                    </div>
                </Form>
                </div>
            </Col>
            </Row>
        </Container>
        }
        </div>
    );
};

const mapStateToPros = (state) => ({
    countryCodes: state.blockCountry.countryInputData,
    saveRestrictAccess: state.blockCountry.saveRestrictAccess,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(RestrictAccessIndex));
