import {
    FETCH_COUNTRY_START,
    FETCH_COUNTRY_SUCCESS,
    FETCH_COUNTRY_FAILURE,
    SAVE_USER_RESTRICT_ACCESS_START,
    SAVE_USER_RESTRICT_ACCESS_SUCCESS,
    SAVE_USER_RESTRICT_ACCESS_FAILURE,
    FETCH_BLOCKED_COUNTRY_START,
    FETCH_BLOCKED_COUNTRY_SUCCESS,
    FETCH_BLOCKED_COUNTRY_FAILURE,
    DELETE_BLOCKED_COUNTRY_START,
    DELETE_BLOCKED_COUNTRY_SUCCESS,
    DELETE_BLOCKED_COUNTRY_FAILURE,
  } from "./ActionConstant";
  
  export function fetchCountryStart(data) {
    return {
      type: FETCH_COUNTRY_START,
      data,
    };
  }
  
  export function fetchCountrySuccess(data) {
    return {
      type: FETCH_COUNTRY_SUCCESS,
      data,
    };
  }
  
  export function fetchCountryFailure(error) {
    return {
      type: FETCH_COUNTRY_FAILURE,
      error,
    };
  }
  
  export function saveUserRestrictAccessStart(data) {
    return {
      type: SAVE_USER_RESTRICT_ACCESS_START,
      data,
    };
  }
  
  export function saveUserRestrictAccessSuccess(data) {
    return {
      type: SAVE_USER_RESTRICT_ACCESS_SUCCESS,
      data,
    };
  }
  
  export function saveUserRestrictAccessFailure(error) {
    return {
      type: SAVE_USER_RESTRICT_ACCESS_FAILURE,
      error,
    };
  }

  export function fetchBlockedCountryStart(data) {
    return {
      type: FETCH_BLOCKED_COUNTRY_START,
      data,
    };
  }
  
  export function fetchBlockedCountrySuccess(data) {
    return {
      type: FETCH_BLOCKED_COUNTRY_SUCCESS,
      data,
    };
  }
  
  export function fetchBlockedCountryFailure(error) {
    return {
      type: FETCH_BLOCKED_COUNTRY_FAILURE,
      error,
    };
  }

  export function deleteBlockedCountryStart(data) {
    return {
      type: DELETE_BLOCKED_COUNTRY_START,
      data,
    };
  }
  
  export function deleteBlockedCountrySuccess(data) {
    return {
      type: DELETE_BLOCKED_COUNTRY_SUCCESS,
      data,
    };
  }
  
  export function deleteBlockedCountryFailure(error) {
    return {
      type: DELETE_BLOCKED_COUNTRY_FAILURE,
      error,
    };
  }