import {
    FETCH_COUNTRY_START,
    FETCH_COUNTRY_SUCCESS,
    FETCH_COUNTRY_FAILURE,
    SAVE_USER_RESTRICT_ACCESS_START,
    SAVE_USER_RESTRICT_ACCESS_SUCCESS,
    SAVE_USER_RESTRICT_ACCESS_FAILURE,
    FETCH_BLOCKED_COUNTRY_START,
    FETCH_BLOCKED_COUNTRY_SUCCESS,
    FETCH_BLOCKED_COUNTRY_FAILURE,
    DELETE_BLOCKED_COUNTRY_START,
    DELETE_BLOCKED_COUNTRY_SUCCESS,
    DELETE_BLOCKED_COUNTRY_FAILURE,
  } from "../actions/ActionConstant";
  
const initialState = {
    countryInputData: {
      data: {},
      loading: true,
      error: false,
      buttonDisable: false,
      loadingButtonContent: null,
    },
    stateCodes: {
      data: {},
      loading: true,
      error: false,
    },
    saveRestrictAccess: {
      data: {},
      loading: true,
      error: false,
      inputData: {},
      loadingButtonContent: null,
      buttonDisable: false,
    },
    blockedCountries: {
        data: {
          countryData: [],
        },
        loading: true,
        error: false,
        skip: 0,
        length: 0,
    },
    delblockedCountry: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
      },
  };
  
  const BlockCountryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BLOCKED_COUNTRY_START:
            return {
                ...state,
                blockedCountries: {
                    data: {
                        countryData: [...state.blockedCountries.data.countryData],
                    },
                    loading: true,
                    error: false,
                    skip: state.blockedCountries.skip,
                    length: state.blockedCountries.length,
                },
            };
        case FETCH_BLOCKED_COUNTRY_SUCCESS:
            return {
                ...state,
                blockedCountries: {
                    data: {
                        countryData: [...state.blockedCountries.data.countryData, ...action.data],
                    },
                    loading: false,
                    error: false,
                    skip: action.data.length + state.blockedCountries.skip,
                    length: action.data.length,
                },
            };
        case FETCH_BLOCKED_COUNTRY_FAILURE:
            return {
                ...state,
                blockedCountries: {
                    data: {},
                    loading: true,
                    error: action.error,
                    skip: state.blockedCountries.skip,
                    length: state.blockedCountries.length,
                },
            };
        case FETCH_COUNTRY_START:
            return {
            ...state,
                countryInputData: {
                    data: action.data,
                    loading: true,
                    error: false,
                },
            };
  
        case FETCH_COUNTRY_SUCCESS:
            return {
                ...state,
                countryInputData: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_COUNTRY_FAILURE:
            return {
                ...state,
                countryInputData: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
    
      case SAVE_USER_RESTRICT_ACCESS_START:
            return {
                ...state,
                saveRestrictAccess: {
                    data: {},
                    loading: true,
                    error: false,
                    inputData: action.data,
                    loadingButtonContent: "Loading... Please wait.",
                    buttonDisable: true,
                },
            };
      case SAVE_USER_RESTRICT_ACCESS_SUCCESS:
            return {
                ...state,
                saveRestrictAccess: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
      case SAVE_USER_RESTRICT_ACCESS_FAILURE:
            return {
                ...state,
                saveRestrictAccess: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case DELETE_BLOCKED_COUNTRY_START:
            return {
                ...state,
                delblockedCountry: {
                    inputData: action.data,
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                },
            };
        case DELETE_BLOCKED_COUNTRY_SUCCESS:
            return {
                ...state,
                delblockedCountry: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case DELETE_BLOCKED_COUNTRY_FAILURE:
            return {
                ...state,
                delblockedCountry: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
      default:
        return state;
    }
  };
  
  export default BlockCountryReducer;
  