
import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_USER_STORIES_START,
  STORY_FILE_UPLOAD_START
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";

import {
  fetchuserStoriesSucess,
  fetchUserStoriesFailure,
  storyFileUploadSuccess,
  storyFileUploadFailure,
  fetchUserStoriesStart
} from "../actions/StoriesAction";

function* fetchUserStoriesAPI() {
  try {
    const response = yield api.postMethod("stories_home");

    if (response.data.success) {
      yield put(fetchuserStoriesSucess(response.data.data));
    } else {
      yield put(fetchUserStoriesFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchUserStoriesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* storyFileUploadAPI(action) {
  try {
    const response = yield api.postMethod("story_files_upload" , action.data);

    if (response.data.success) {
      yield put(storyFileUploadSuccess(response.data.data));
      yield put(fetchUserStoriesStart())
    } else {
      yield put(storyFileUploadFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(storyFileUploadFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_USER_STORIES_START, fetchUserStoriesAPI)]);
  yield all([yield takeLatest(STORY_FILE_UPLOAD_START, storyFileUploadAPI)]);
}
