import React, { useState } from 'react'
import { useEffect } from 'react';
import Stories  from 'react-insta-stories';

const StoriesSliderModal = (props) => {

  const [modalData , setModalData] = useState([])

  useEffect(() => {

    const dataArray = []

    props.data.map((data) => {
      dataArray.push({
        url : data.storyFiles[0].story_file,
        header: {
          heading: data.user_displayname,
          subheading: data.updated_at,
          profileImage: data.user.picture
        }
      })
    })
    setModalData([...modalData , ...dataArray])
  },[])

  return (
    <>
      <div class={`modal custom-modal fade`} id="storiesSliderModal" tabindex="-1" role="dialog" aria-labelledby="storiesSliderModal" aria-hidden="true" onClick={() => props.SliderModalToggle(false)}> 
        <div class="modal-dialog modal-md" role="document">
          <div class="modal-content">
            <div class="modal-body">
              {modalData.length > 0 && (
                <Stories
                  currentIndex={props.selectedSliderIndex == 0 ? null : props.selectedSliderIndex}
                  stories={modalData}
                  defaultInterval={3000}
                  width={"100%"}
                  height={window.innerHeight - 100}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StoriesSliderModal
